import { ViteSSG } from 'vite-ssg';
import App from './App.vue';
import { routes } from './router';
import './assets/styles/global.css';

// Import createHead but don't create the instance immediately
import { createHead } from '@unhead/vue';

export const createApp = ViteSSG(
  App,
  { routes },
  ({ app, router, isClient }) => {
    // Only create and use head if it's not already provided
    if (!app._context.provides.usehead) {
      const head = createHead();
      app.use(head);
    }
    
    if (isClient) {
      router.beforeEach((to, from, next) => {
        if (to.path.startsWith('/domains/')) {
          const domainName = decodeURIComponent(to.path.split('/').pop().replace('.html', ''));
          to.params.domainName = domainName;
        }
        next();
      });
    }
  }
);