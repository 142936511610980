<script>
import domains from "../test_domains.json"; // Adjusted to relative path for build compatibility

export default {
  data() {
    console.log("DomainList.vue - data() initialized");
    console.log("Preloaded domains from test_domains.json:", domains);
    return {
      domains: domains, // Preloaded domains from static file
      searchQuery: "",
    };
  },
  computed: {
    filteredDomains() {
      console.log("DomainList.vue - filteredDomains computation triggered");
      const query = this.searchQuery.trim().toLowerCase();
      console.log("Current normalized search query:", query);

      const filtered = query
        ? this.domains.filter((domain) => {
            const nameMatch = domain.name.toLowerCase().includes(query);
            const keywordMatch =
              Array.isArray(domain.keywords) &&
              domain.keywords.some((keyword) =>
                keyword.toLowerCase().includes(query)
              );
            const descriptionMatch =
              domain.description &&
              domain.description.toLowerCase().includes(query);

            console.log(
              `Domain: ${domain.name}, Name match: ${nameMatch}, Keywords match: ${keywordMatch}, Description match: ${descriptionMatch}`
            );

            return nameMatch || keywordMatch || descriptionMatch;
          })
        : this.domains;

      console.log("Filtered domains count:", filtered.length);

      // Sort domains alphabetically
      const sorted = filtered.sort((a, b) => a.name.localeCompare(b.name));
      console.log("Sorted filtered domains:", sorted.map((d) => d.name));
      return sorted;
    },
  },
  methods: {
    handleSearch() {
      console.log("DomainList.vue - handleSearch triggered");
      console.log("Current search query:", this.searchQuery);
    },
  },
};
</script>

<template>
  <div class="domain-list">
    <!-- Search Bar -->
    <div class="search-bar">
      <input
        v-model="searchQuery"
        placeholder="Search domains..."
        type="text"
        class="search-input"
        @input="handleSearch"
      />
      <button @click="handleSearch" class="search-button">Search</button>
    </div>

    <!-- Domains -->
    <div v-if="filteredDomains.length > 0" class="domains-container">
      <div
        v-for="domain in filteredDomains"
        :key="domain.name"
        class="domain-item"
      >
        <a
          :href="`/domains/${domain.name}`"
          class="domain-link"
        >
          <h2>{{ domain.name }}</h2>
        </a>
        <p>{{ domain.description ? 'Pitch: ' + domain.description : '' }}</p>
      </div>
    </div>
    <div v-else>
      <h3>No domains found.</h3>
    </div>
  </div>
</template>