<template>
  <div class="faq-page">
    <div class="faq-container">
      <h1 class="faq-title">Frequently Asked Questions</h1>
      
      <h2 class="faq-subtitle">General Information</h2>

      <div class="faq-section">
        <h3 class="faq-question">What is this site about?</h3>
        <p class="faq-answer">
          This site is an opportunity for skilled entrepreneurs and developers to create value around one or more of our premium domain names. We bring the identity in the form of premium curated domain names.
        </p>
      </div>

      <div class="faq-section">
        <h3 class="faq-question">Do you sell domains?</h3>
        <p class="faq-answer">
          Yes we do. And we will, in certain cases, co-develop the business around the domain.
        </p>
      </div>

      <div class="faq-section">
        <h3 class="faq-question">What's the difference between buying the domain from a broker like Afternic, and buying it directly from you?</h3>
        <p class="faq-answer">
          If you buy direct, you'll save an average of 5% from what you would pay if you bought via a broker.  We always use escrow.com, which is a reputable and proven company that ensures the safety of all parties.
        </p>
      </div>

      <div class="faq-section">
        <h3 class="faq-question">Is buying direct safe?</h3>
        <p class="faq-answer">
          Yes, we use Escrow.com for all transactions. We are not paid until you receive full access to the domain name you bought.
        </p>
      </div>

      <div class="faq-section">
        <h3 class="faq-question">Can I pay with crypto?</h3>
        <p class="faq-answer">
          In certain cases, yes.  In most cases, no.  We would likely only accept BTC, ETH, or USDC
        </p>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue"; // Adjust the path based on your project structure

export default {
  name: "FAQPage",
  components: {
    Footer,
  },
};
</script>
