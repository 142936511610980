<template>
  <footer class="app-footer">
    <div class="footer-container">
      <p class="footer-text">© 2025 Baj.ai All rights reserved.</p>
      <nav class="footer-nav">
        <ul class="footer-links">
          <li><router-link to="/" class="footer-link">Home</router-link></li>
          <li><router-link to="/about" class="footer-link">About</router-link></li>
          <li><router-link to="/contact" class="footer-link">Inquiry</router-link></li>
          <li><router-link to="/faq" class="footer-link">FAQ</router-link></li>
        </ul>
      </nav>
    </div>
  </footer>
</template>