<template>
  <div class="app">
    <header class="header">
      <!-- Search bar commented out -->
    </header>
    <router-view />
  </div>
</template>

<script>
import './assets/styles/global.css';
import Involved from './components/Involved.vue';

export default {
  components: {
    Involved,
  },
  data() {
    console.log("App.vue - data() initialized");
    return {
      searchQuery: "",
      lastProcessedQuery: null,
    };
  },
  methods: {
    search() {
      console.log("App.vue - search method triggered");
      console.log("Current searchQuery before trimming:", this.searchQuery);

      const trimmedQuery = this.searchQuery.trim();
      console.log("Trimmed searchQuery:", trimmedQuery);

      if (trimmedQuery === this.lastProcessedQuery) {
        console.log("App.vue - Search query unchanged; skipping router push.");
        return;
      }

      this.$router.push({
        name: "Home",
        query: { q: trimmedQuery || undefined },
      });
      console.log("Router updated with query:", trimmedQuery);

      if (!trimmedQuery) {
        console.warn("App.vue - Empty searchQuery submitted.");
      }

      this.lastProcessedQuery = trimmedQuery;
      console.log("App.vue - lastProcessedQuery updated:", this.lastProcessedQuery);
    },
    logState() {
      console.log("=== App.vue Current State ===");
      console.log("searchQuery:", this.searchQuery);
      console.log("Route query.q:", this.$route.query.q);
      console.log("lastProcessedQuery:", this.lastProcessedQuery);
      console.log("=============================");
    },
    updateCanonicalTag() {
      // Skip during SSR
      if (import.meta.env.SSR) return;
      
      // Remove existing canonical tags
      const existingCanonical = document.querySelector("link[rel='canonical']");
      if (existingCanonical) {
        existingCanonical.remove();
      }

      // Add a new canonical tag
      const canonical = document.createElement("link");
      canonical.rel = "canonical";
      canonical.href = `${window.location.origin}${this.$route.fullPath}`;
      document.head.appendChild(canonical);

      console.log("Canonical tag updated:", canonical.href);
    },
  },
  created() {
    console.log("App.vue - created lifecycle hook triggered");

    this.searchQuery = this.$route.query.q || "";
    this.lastProcessedQuery = this.searchQuery;
    console.log("Initial query loaded in App.vue:", this.searchQuery);

    // Only update canonical tag on client-side
    if (!import.meta.env.SSR) {
      this.updateCanonicalTag();
    }

    this.logState();
  },
  watch: {
    "$route.query.q": {
      handler(newQuery) {
        console.log("App.vue - Watcher triggered for $route.query.q");
        console.log("New query value:", newQuery);

        if (newQuery === this.lastProcessedQuery) {
          console.log("App.vue - Watcher detected no change in query. Skipping update.");
          return;
        }

        this.searchQuery = newQuery || "";
        console.log("searchQuery updated from watcher:", this.searchQuery);

        this.logState();

        this.lastProcessedQuery = this.searchQuery;
        console.log("App.vue - lastProcessedQuery updated by watcher:", this.lastProcessedQuery);
      },
      immediate: true,
    },
    "$route.fullPath": {
      handler() {
        console.log("Route changed. Updating canonical tag.");
        // Only update canonical tag on client-side
        if (!import.meta.env.SSR) {
          this.updateCanonicalTag();
        }
      },
      immediate: true,
    },
  },
};
</script>