<template>
  <div class="domain-page">
    <div v-if="isLoading">
      <LoadingMessages />
    </div>
    <div v-else-if="localDomain">
      <div class="domain-container">
        <div class="domain-header">
          <img 
            :src="selectedImage" 
            :alt="'Abstract illustration for ' + localDomain.name"
            class="domain-image"
          />
          <h1 class="domain-title">{{ localDomain.name }} premium domain name is available for purchase. Make it yours today. Click for details</h1>
        </div>
        
        <p class="domain-description">
          <strong>Sample concept:</strong> {{ localDomain.description }}
        </p>
        
        <p class="features-list">
          <strong>Features:</strong> {{ localDomain.features.join(', ') }}
        </p>

        <p class="keywords-list">
          <strong>Keywords:</strong> {{ localDomain.keywords.join(', ') }}
        </p>

        <div class="button-container">
          <a :href="generateEmailLink(localDomain.name)" class="contact-button">
            Contact the domain owner directly
          </a>
          <a :href="'https://' + localDomain.name" target="_blank" class="buy-now-button">
            Buy the domain now
          </a>
        </div>
      </div>
    </div>
    <div v-else class="domain-not-found">
      <h1>Domain Not Found</h1>
      <p>The requested domain could not be found in our records.</p>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import LoadingMessages from "@/components/LoadingMessages.vue";

let testDomains = [];

export default {
  name: 'DomainPage',
  components: {
    Footer,
    LoadingMessages
  },
  props: {
    domainName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      localDomain: null,
      isLoading: true,
      selectedImage: null,
      loadStartTime: null
    };
  },
  methods: {
    generateEmailLink(domainName) {
      const email = 'premiumaidomains@gmail.com';
      const subject = encodeURIComponent(`Inquiry about ${domainName}`);
      const body = encodeURIComponent(`I am interested in the domain ${domainName}. Please provide more information about its availability and pricing.`);
      return `mailto:${email}?subject=${subject}&body=${body}`;
    },
    selectRandomImage() {
      const imageCount = 32;
      const randomNum = Math.floor(Math.random() * imageCount) + 1;
      this.selectedImage = `/images/${randomNum}.svg`;
    },
    completeLoading(domain) {
      const now = Date.now();
      const loadingDuration = now - this.loadStartTime;
      const minimumDuration = 2000; // 2 seconds minimum loading time

      if (loadingDuration >= minimumDuration) {
        this.finishLoading(domain);
      } else {
        const remainingTime = minimumDuration - loadingDuration;
        setTimeout(() => {
          this.finishLoading(domain);
        }, remainingTime);
      }
    },
    finishLoading(domain) {
      this.localDomain = domain;
      this.isLoading = false;
    },
    loadDomainData() {
      this.loadStartTime = Date.now();
      this.isLoading = true;
      
      fetch('/test_domains.json')
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          testDomains = data;
          const resolvedDomain = testDomains.find(
            (domain) => domain.name.trim().toLowerCase() === this.domainName.toLowerCase()
          );
          this.completeLoading(resolvedDomain);
        })
        .catch((error) => {
          console.error('[ERROR] Failed to fetch testDomains.json:', error);
          this.completeLoading(null);
        });
    }
  },
  watch: {
    // Watch for route changes to reload data
    '$route'(to, from) {
      if (to.path !== from.path) {
        this.selectRandomImage();
        this.loadDomainData();
      }
    }
  },
  created() {
    this.selectRandomImage();
    this.loadDomainData();
  },
  head() {
    return {
      title: this.localDomain ? `${this.localDomain.name} - Premium Domain For Sale` : 'Domain Not Found',
      meta: [
        {
          name: 'description',
          content: this.localDomain 
            ? `${this.localDomain.name} premium domain name is available for purchase. Secure this domain today.`
            : 'Domain information not found'
        }
      ]
    }
  }
};
</script>