import HomePage from "@/components/HomePage.vue";
import DomainPage from "@/components/DomainPage.vue";
import AboutPage from "@/components/About.vue";
import FAQPage from "@/components/FAQ.vue";
import InvolvedPage from "@/components/Involved.vue";
import testDomains from "@/test_domains.json";

const staticRoutes = [
  { path: "/", name: "Home", component: HomePage },
  { path: "/about", name: "About", component: AboutPage },
  { path: "/contact", name: "Contact", component: InvolvedPage },
  { path: "/faq", name: "FAQ", component: FAQPage }
];

const validDomains = testDomains.filter((domain, index) => {
  if (!domain.name || typeof domain.name !== "string") {
    console.error(`Invalid domain entry at index ${index}:`, domain);
    return false;
  }
  return true;
});

// Create two routes for each domain - one with .html and one without
const domainRoutes = validDomains.flatMap((domain) => [
  {
    path: `/domains/${encodeURIComponent(domain.name)}`,
    name: `domain-${domain.name}`,
    component: DomainPage,
    props: route => ({ 
      domainName: decodeURIComponent(domain.name)
    })
  },
  {
    path: `/domains/${encodeURIComponent(domain.name)}.html`,
    name: `domain-${domain.name}-html`,
    component: DomainPage,
    props: route => ({ 
      domainName: decodeURIComponent(domain.name)
    })
  }
]);

// Add a catch-all route for domains to handle direct access
const fallbackRoute = {
  path: '/domains/:pathMatch(.*)*',
  name: 'domain-fallback',
  component: DomainPage,
  props: route => ({
    domainName: decodeURIComponent(route.params.pathMatch.replace('.html', ''))
  })
};

export const routes = [...staticRoutes, ...domainRoutes, fallbackRoute];