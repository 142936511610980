<template>
  <div class="loading-messages">
    <p class="loading-text">{{ currentMessage }}</p>
  </div>
</template>

<script>
export default {
  name: 'LoadingMessages',
  data: function() {
    return {
      // Default message shown before quips load - you can modify this initial message
      currentMessage: 'Loading domain information...',
      messages: [],
      messageIndex: 0,
      messageTimer: null,
      usedIndices: new Set()  // Track which messages we've used
    }
  },
  methods: {
    loadQuips: async function() {
      console.log('Starting to load quips...');
      try {
        // First try direct path
        let response = await fetch('/loadingquips.txt');
        if (!response.ok) {
          console.log('Direct path failed, trying with public...');
          // Try with /public prefix
          response = await fetch('/public/loadingquips.txt');
        }
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        const text = await response.text();
        console.log('Loaded text:', text);
        
        const lines = text.split('\n').filter(line => line.trim().length > 0);
        console.log('Parsed lines:', lines);
        
        if (lines.length > 0) {
          this.messages = lines;
          this.currentMessage = this.getRandomMessage();
          console.log('Set messages to:', this.messages);
        } else {
          console.log('No valid lines found in file');
        }
        this.rotateMessage();
      } catch (error) {
        console.error('Failed to load quips:', error);
        // You can modify these fallback messages that show if the file fails to load
        this.messages = [
          'Loading domain information...',
          'Please wait...'
        ];
        this.rotateMessage();
      }
    },
    getRandomMessage: function() {
      // If we've used all messages, reset the tracking
      if (this.usedIndices.size >= this.messages.length) {
        this.usedIndices.clear();
      }
      
      // Get available indices (those we haven't used yet)
      const availableIndices = Array.from(Array(this.messages.length).keys())
        .filter(i => !this.usedIndices.has(i));
      
      // Pick a random index from available ones
      const randomIndex = availableIndices[Math.floor(Math.random() * availableIndices.length)];
      
      // Track that we've used this index
      this.usedIndices.add(randomIndex);
      
      return this.messages[randomIndex];
    },
    rotateMessage: function() {
      if (this.messages.length <= 1) {
        console.log('Not starting rotation - insufficient messages');
        return;
      }
      
      console.log('Starting message rotation');
      // TIMING CONFIGURATION: Adjust the interval (in milliseconds) to control how long each message displays
      // Currently set to 1.5 seconds (1500ms). Examples:
      // 1000 = 1 second per message
      // 2000 = 2 seconds per message
      // 3000 = 3 seconds per message
      this.messageTimer = setInterval(() => {
        this.currentMessage = this.getRandomMessage();
      }, 250);  // <-- Modify this number to change message display duration
    }
  },
  mounted: function() {
    console.log('LoadingMessages component mounted');
    this.loadQuips();
  },
  beforeDestroy: function() {
    if (this.messageTimer) {
      clearInterval(this.messageTimer);
    }
  }
}
</script>