<template>
  <div class="involved-container">
    <h1>Contact Us</h1>
    <p>
      To inquire about purchasing, leasing, or developing a domain, please 
      <a 
        href="mailto:premiumAIdomains@gmail.com?subject=Interested%20in%20a%20Domain%20Name" 
        class="contact-link"
      >
        contact us
      </a>.
    </p>
    <Footer />
  </div>
</template>

<script>
import Footer from './Footer.vue'; // Import the Footer component

export default {
  name: "Involved",
  components: {
    Footer, // Register the Footer component
  },
};
</script>

<style scoped>
.involved-container {
  padding: 2rem;
  text-align: center;
  font-family: Arial, sans-serif;
}

.contact-link {
  color: #007bff;
  text-decoration: none;
}

.contact-link:hover {
  text-decoration: underline;
}
</style>
