<template>
  <div class="home-page">
    <div class="content">
      <header>
        <h1>Welcome to Baj.ai</h1>
        <p class="intro-subheading">Transforming high-value domains into successful enterprises</p>
        <p class="intro-text">
          We believe that exceptional domain names are more than just web addresses—they're the cornerstones of memorable brands and successful businesses.
          Through strategic curation and collaboration with best-in-class entrepreneurs, we're enabling many next-generation companies to build on the strongest possible domain names.
        </p>
        <p class="intro-text">Let us help you build something extraordinary!</p>
      </header>

      <!-- Search and Browse Domains -->
      <section class="search-section">
        <h2 class="headline">Search and Browse Domains</h2>
        <DomainList />
      </section>

      <!-- Available Domains -->
      <section>
        <h2>Available Domains</h2>
        <ul class="domain-list">
          <li
            v-for="domain in sortedDomains"
            :key="domain.name"
            class="domain-item"
          >
            <router-link
              :to="`/domains/${domain.name}.html`"
              class="domain-link"
            >
              {{ domain.name }}
            </router-link>
          </li>
        </ul>
      </section>
    </div>

    <Footer />
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import DomainList from "@/components/DomainList.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "HomePage",
  components: {
    DomainList,
    Footer,
  },
  setup() {
    const domains = ref([]);

    const fetchDomains = async () => {
      try {
        console.log("[HomePage] Fetching test_domains.json dynamically in client");
        const response = await fetch("/test_domains.json");
        if (response.ok) {
          const data = await response.json();
          console.log("[HomePage] Fetched Domains:", data);
          domains.value = data;
        } else {
          console.error(
            "[HomePage] Failed to fetch test_domains.json:",
            response.statusText
          );
        }
      } catch (error) {
        console.error("[HomePage] Error fetching test_domains.json:", error);
      }
    };

    const sortedDomains = computed(() => {
      return domains.value.sort((a, b) => a.name.localeCompare(b.name));
    });

    onMounted(() => {
      fetchDomains();
    });

    return {
      domains,
      sortedDomains,
    };
  },
};
</script>

<style scoped>
.home-page {
  text-align: center;
  font-family: Arial, sans-serif;
  padding: 2em;
  background: linear-gradient(to bottom, #f7fff7, #e6ffe6);
}

header {
  margin-bottom: 2em;
}

h1 {
  font-size: 2.5em;
  margin-bottom: 0.5em;
  color: #007bff;
}

.intro-subheading {
  font-size: 1.5em;
  color: #007bff;
  margin-bottom: 1em;
}

h2 {
  font-size: 1.8em;
  margin-bottom: 1em;
  color: #007bff;
}

.search-section {
  margin-bottom: 2em;
}

ul.domain-list {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1em;
}

li.domain-item {
  margin: 0.5em 0;
}

a.domain-link,
router-link.domain-link {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

a.domain-link:hover,
router-link.domain-link:hover {
  text-decoration: underline;
  color: #005f3f;
}

.loading {
  color: gray;
  font-style: italic;
}

.error {
  color: red;
  font-weight: bold;
}

.empty {
  color: #666;
  font-style: italic;
}
</style>